import React from 'react';
import {Link} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa';
const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t border-b py-30">

                    {/* Top area: Blocks */}
                    <div className="grid sm:grid-cols-12 gap-5 py-8 md:py-12 border-t border-gray-200 lg:ml-11">

                    {/* 1st block */}
                    <div className="col-span-12 lg:col-span-4">
                        <div className="box-border border-b-4 border-blue-900 p-8 bg-gray-200 text-gray-600 text-center rounded-lg xl:w-80 mx-auto">
                            <h3 className="font-bold text-4xl mb-4">Mahann</h3>
                            <div className='text-md font-medium text-gray-600'>
                                <h5>Inovação,</h5>
                                <p>Eficiência,</p>
                                <p>Conectividade,</p>
                                <p>e Transformação.</p>
                            </div>
                        </div>
                    </div>

                    {/* 2nd block */}
                    <div className="col-span-6 md:col-span-6 lg:col-span-1 ml-7 mx-auto">
                        <h6 className="text-white text-xl font-bold mb-4">LINKS</h6>
                        <ul className="text-md">
                        <li className="mb-2">
                            <HashLink to="/#about" className="text-white hover:text-gray-100 hover:tracking-wider transition duration-250 ease-in-out">Sobre</HashLink>
                        </li>
                        <li className="mb-2">
                            <HashLink to="/#services" className="text-white hover:text-gray-100 hover:tracking-wider transition duration-250 ease-in-out">Serviços</HashLink>
                        </li>
                        <li className="mb-2">
                            <HashLink to="/contact#contact" className="text-white hover:text-gray-100 hover:tracking-wider transition duration-250 ease-in-out">Contatos</HashLink>
                        </li>                            
                        </ul>
                    </div>

                    {/* 3rd block */}
                    <div className="col-span-6 md:col-span-6 lg:col-span-4 mx-auto">
                        <h6 className="text-white text-xl font-bold mb-4">OUR SERVICES</h6>
                        <ul className="text-md">
                        <li className="mb-2">
                            <Link to="#" className="text-white hover:text-gray-100 hover:tracking-wider transition duration-250 ease-in-out">Web Development</Link>
                        </li>
                        <li className="mb-2">
                            <Link to="#" className="text-white hover:text-gray-100 hover:tracking-wider transition duration-250 ease-in-out">Mobile App Development</Link>
                        </li>
                        <li className="mb-2">
                            <Link to="#" className="text-white hover:text-gray-100 hover:tracking-wider transition duration-250 ease-in-out">Domain and Hosting</Link>
                        </li>
                        <li className="mb-2">
                            <Link to="#" className="text-white hover:text-gray-100 hover:tracking-wider transition duration-250 ease-in-out">General IT Consultations</Link>
                        </li>
                        </ul>
                    </div>

                    {/* 4th block */}
                    <div className="col-span-12 text-center mx-auto lg:col-span-3 font-bold uppercase text-white">
                        <div className="text-xl mb-6">
                            Redes Sociais.
                        </div>

                                <div className="text-md font-medium mb-6">
                                Siga-nos nas redes sociais..
                                </div>
                                <div className="mx-auto text-center mt-2 flex justify-center space-x-4">
  <a href="https://www.facebook.com/it.mahann" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-white">
    <FaFacebook size={24} />
  </a>
  <a href="https://www.instagram.com/mahann_it" target="_blank" rel="noopener noreferrer" className="text-pink-600 hover:text-white">
    <FaInstagram size={24} />
  </a>
  <a href="https://wa.me/554120182138" target="_blank" rel="noopener noreferrer" className="text-green-600 hover:text-white">
    <FaWhatsapp size={24} />
  </a>
</div>


                    </div>          

                    </div>

                    <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
                <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
                    <div className="text-sm text-gray-200 font-semibold py-1">
                    Copyright &copy; {new Date().getFullYear()}{"  "}
                    <HashLink
                        to="#"
                        className=" hover:text-gray-900"
                    >
                        Mahann IT Solutions
                    </HashLink>. All rights reserved.
                    </div>
                </div>
                </div>

                </div>
                
            </footer>
        </>
    )
}
export default Footer;
