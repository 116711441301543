import React, { useState } from 'react';
import NavLinks from './NavLinks'; // Ajuste o caminho conforme necessário
import { HashLink } from 'react-router-hash-link';
import logo from '../../images/logo.png'; // Verifique se o caminho da logo está correto

const NavBar = () => {
    const [isOpen, setisOpen] = useState(false);
    
    function handleClick() {
        setisOpen(!isOpen);
    }

    function closeMenu() {
        setisOpen(false); // Fecha o menu ao clicar em qualquer link
    }

    return (
        <nav className="fixed top-0 w-full z-30 bg-blue-900 transition duration-300 ease-in-out">
            <div className="flex flex-row justify-between items-center py-2 px-4">
                <div className="flex items-center">
                    <HashLink smooth to="/#hero" onClick={closeMenu}>
                        <img 
                            src={logo}
                            alt="Logo" 
                            className="h-16 w-auto block lg:hidden" // Responsivo: ajusta conforme o layout
                        />
                    </HashLink>
                </div>
                <div className="flex items-center">
                    {/* Botão para abrir/fechar o menu no mobile */}
                    <button className="p-2 rounded-lg lg:hidden text-white focus:outline-none" onClick={handleClick}>
                        <svg className="h-6 w-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            {isOpen ? (
                            <path fillRule="evenodd" clipRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" />
                            ) : (
                            <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2z" />
                            )}
                        </svg>
                    </button>

                    {/* Menu para telas grandes */}
                    <div className='hidden lg:flex space-x-6'>
                        <NavLinks closeMenu={closeMenu} />
                    </div>
                </div>
            </div>

            {/* Menu para telas pequenas */}
            <div className={`lg:hidden bg-blue-900 shadow-xl rounded-b-lg transition-all duration-300 ease-in-out ${isOpen ? 'block' : 'hidden'}`}>
                <div className="p-5">
                    <NavLinks closeMenu={closeMenu} isOpen={isOpen} /> {/* Passa o estado e função de fechamento */}
                </div>
            </div>
        </nav>
    );
}

export default NavBar;
