import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa'; // Importando ícones das redes sociais

const NavLinks = ({ isOpen, closeMenu }) => {
    return (
        <nav className="flex flex-col lg:flex-row justify-between items-center space-y-4 lg:space-y-0 lg:space-x-6">
            <div className="flex flex-col lg:flex-row lg:space-x-6">
            <HashLink className="px-4 py-2 font-extrabold text-white hover:text-gray-400" to="/#" onClick={closeMenu}>
                    Home
                </HashLink>
                <HashLink className="px-4 py-2 font-extrabold text-white hover:text-gray-400" smooth to="/#about" onClick={closeMenu}>
                    Sobre
                </HashLink>
                <HashLink className="px-4 py-2 font-extrabold text-white hover:text-gray-400" smooth to="/#services" onClick={closeMenu}>
                    Serviços
                </HashLink>
                <HashLink className="px-4 py-2 font-extrabold text-white hover:text-gray-400" to="/contact#contact" onClick={closeMenu}>
                    Contatos
                </HashLink>
            </div>

            {/* Redes sociais */}
            <div className="mx-auto text-center mt-2 flex justify-center space-x-4">
  <a href="https://www.facebook.com/it.mahann" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-white">
    <FaFacebook size={24} />
  </a>
  <a href="https://www.instagram.com/mahann_it" target="_blank" rel="noopener noreferrer" className="text-pink-600 hover:text-white">
    <FaInstagram size={24} />
  </a>
  <a href="https://wa.me/554120182138" target="_blank" rel="noopener noreferrer" className="text-green-600 hover:text-white">
    <FaWhatsapp size={24} />
  </a>
</div>

        </nav>
    );
}

export default NavLinks;
